import { type ChangeEvent, type FC } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './FeatureHeroFormPhone.module.scss';
import Form from '#components/shared/ui/Form';
import Input from '#components/shared/ui/Input';
import Button from '#components/shared/ui/Button';
import schema from '#schemas/phone.ts';
import { urlBuilder } from '#utils/urlBuilder.ts';
import { anim2Url } from '#constants/testPage.ts';
import analytics from '#utils/analytics.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import { FeatureHeroTypes } from '#types/FeatureHeroTypes.ts';
import parsePhoneNumber from 'libphonenumber-js';
import clsx from 'clsx';

type FormValues = {
  phone: string;
};

const FeatureHeroFormPhone: FC<FeatureHeroCommonProps> = ({
  t,
  isNsr,
  isDc,
  feature,
  isPeopleSearch,
  onSubmitAction,
}) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { phone: '' },
  });
  const { handleSubmit, formState, register } = form;
  const isPhoneLookup = feature === FeatureHeroTypes.phoneLookup;
  const isPhoneSearch = feature === FeatureHeroTypes.phoneSearch;
  const isPhoneSearch1 = feature === FeatureHeroTypes.phoneSearch1;
  const placeholder = {
    phoneSearch: t.lookups.phoneSearch.placeholder,
    phoneSearch1: t.lookups.phoneSearch1.placeholder,
    phone: t.lookups.phone.placeholder,
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/[^0-9()+\-\s]/g, '');
    form.setValue('phone', value);
  };

  const onSubmit = (values: FormValues) => {
    analytics.event({
      action: 'search_phone',
    });

    const phoneNumber = parsePhoneNumber(values.phone, 'US'); //US phone number parser by default (temporary)
    const { nationalNumber } = phoneNumber || {};

    const phone = nationalNumber || values.phone;

    if (onSubmitAction) onSubmitAction();

    if (isPhoneSearch || isPhoneSearch1) {
      localStorage.setItem(
        'customerSearch',
        JSON.stringify({
          type: 'phone',
          value: {
            phone,
          },
          search: phone,
          searchDate: Date.now(),
        })
      );
      if (isPhoneSearch1) window.location.assign('/phone-search-purchase');
      else window.location.assign('/checkout-new');
      return;
    }

    const isTestAnim = window.location.pathname.includes(anim2Url);
    const url = urlBuilder(
      {
        phone,
        ...(isNsr && { is_nsr: 'true' }),
        ...(isTestAnim && { isAnim2: 'true' }),
      },
      isDc && isPhoneLookup ? '/spf' : undefined
    );
    window.location.assign(url);
  };

  return (
    <Form
      className={clsx(styles.form, {
        [styles.dc]: isDc,
        [styles.peopleSearch]: isPeopleSearch,
      })}
      form={form}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Input
        {...register('phone')}
        placeholder={placeholder[feature as keyof typeof placeholder]}
        wrapperClassName={styles.input}
        error={formState.errors.phone?.message}
        onChange={onChange}
        type="tel"
        data-search
      />
      <Button
        variant="accent"
        size="lg"
        className={clsx(styles.button, isPeopleSearch && styles.peopleSearch)}
      >
        {t.lookups.phone.action}
      </Button>
    </Form>
  );
};

export default FeatureHeroFormPhone;
