import { type BaseSyntheticEvent, type FC } from 'react';
import { Controller, type FieldErrors, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import styles from './FeatureHeroFormName.module.scss';
import schema from '#schemas/name.ts';
import Form from '#components/shared/ui/Form';
import Input from '#components/shared/ui/Input';
import Button from '#components/shared/ui/Button';
import { STATES_FULL_NAMES, STATES_NAMES_CODES_MAP } from '#constants/states.ts';
import Autocomplete from '#components/shared/ui/Autocomplete';
import { urlBuilder } from '#utils/urlBuilder.ts';
import analytics from '#utils/analytics.ts';
import type { FeatureHeroCommonProps } from '#types/feature-hero.ts';
import { anim2Url } from '#constants/testPage.ts';
import clsx from 'clsx';

type FormValues = {
  firstName: string;
  lastName: string;
  state?: string;
};

const FeatureHeroFormName: FC<FeatureHeroCommonProps> = ({
  t,
  isNsr,
  isDc,
  isPeopleSearch,
  onSubmitAction,
}) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: { firstName: '', lastName: '', state: '' },
  });
  const { handleSubmit, formState, register, setValue } = form;

  const onSubmit = ({ state = 'All', firstName, lastName }: FormValues) => {
    analytics.event({
      action: 'search_name',
      event_params: `first_name_value: ${firstName}, last_name_value: ${lastName}`,
    });

    const isTestAnim = window.location.pathname.includes(anim2Url);
    const values = {
      first_name: firstName,
      last_name: lastName,
      state: STATES_NAMES_CODES_MAP[state],
    };
    const url = urlBuilder({
      ...values,
      ...(isTestAnim && { isAnim2: 'true' }),
      ...(isNsr && { is_nsr: 'true' }),
    });

    if (onSubmitAction) onSubmitAction();
    window.location.assign(url);
  };

  const onFail = (errors: FieldErrors<FormValues>, e?: BaseSyntheticEvent) => {
    if (errors.state) setValue('state', '');
  };

  return (
    <Form
      className={clsx(styles.formWrapper, {
        [styles.peopleSearch]: isPeopleSearch,
        [styles.dc]: isDc,
      })}
      form={form}
      onSubmit={handleSubmit(onSubmit, onFail)}
    >
      <div
        className={clsx(styles.form, {
          [styles.dc]: isDc,
          [styles.peopleSearch]: isPeopleSearch,
        })}
      >
        <Input
          {...register('firstName')}
          placeholder="First Name"
          wrapperClassName={styles.item}
          error={formState.errors.firstName?.message}
        />
        <Input
          {...register('lastName')}
          placeholder="Last Name"
          wrapperClassName={styles.item}
          error={formState.errors.lastName?.message}
        />
        <Controller
          name="state"
          control={form.control}
          render={({ field: { ref, value, onChange, name }, formState }) => (
            <Autocomplete
              name={name}
              ref={ref}
              value={value ?? ''}
              placeholder="State"
              className={styles.item}
              data={STATES_FULL_NAMES}
              error={formState.errors.state?.message}
              onValueChanged={(value) => onChange(value)}
              showOptionsOnEmpty
            />
          )}
        />
      </div>
      <Button variant="accent" size="lg" className={styles.button}>
        {t.lookups.name.action}
      </Button>
    </Form>
  );
};

export default FeatureHeroFormName;
